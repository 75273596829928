import React from 'react';

function Selo() {
    return (
        <div>
            <p className="p-title-info"><b>

                Infomações sobre o Selo de Ética</b></p>

            <p className="text-p-info">O Selo de Ética atesta que a organização certificada está de acordo com
             as diretrizes do Código de Ética do PROBARE, podendo ser pleiteado por qualquer Central de Relacionamento
              em operação, independentemente se presta serviços
             a vários contratantes ou se está incorporada a uma empresa (Central de Relacionamento própria).</p>

            <p className="text-p-info">As condições e o regulamento para o Selo de Ética estão contidos no documento
            Regulamento do PROBARE: Para fazer o download do Regulamento do 
            PROBARE <a href="/docs/RegulamentoV3.pdf" target="_blank" rel="noopener noreferrer">clique aqui</a> - Versão 3 Oficial</p>

            <p className="p-title-info-2"><b>Para processo de obtenção do Selo de Ética será seguido o fluxo abaixo:</b></p>

            <p className="text-p-info">A empresa preenche o Termo de Adesão e envia para a Gerenciadora. 
                Termo de Adesão - <a href="/docs/TermoAdesaoEmpresasV10.doc" target="_blank" rel="noopener noreferrer">clique aqui</a></p>

            <p className="text-p-info">A Gerenciadora confirma o recebimento do Termo de Adesão à empresa e envia aos Organismos de Auditoria, homologados no Probare, indicados pela empresa, para emissão de proposta.</p>

            <p className="text-p-info">O(s) Organismo(s) de Auditoria entra(m) em contato com a empresa, para negociação direta sobre o valor da auditoria.</p>
            <p className="text-p-info">A empresa contrata um dos Organismos de Auditoria.</p>

            <p className="text-p-info">O Organismo de Auditoria escolhido realiza a auditoria e emite Relatório com parecer.</p>

            <p className="text-p-info">O Comitê Executivo, com base no parecer do Organismo de Auditoria, poderá conceder o Selo de Ética.</p>

            <p className="text-p-info">Após a confirmação da certificação do Selo de Ética, a Gerenciadora informa a empresa sobre o procedimento para pagamento da taxa anual do Probare no valor de R$ 3.000,00.</p>

            <p className="text-p-info">A empresa efetua o pagamento da taxa anual à Gerenciadora do Probare.</p>

            <p className="text-p-info">A Gerenciadora envia à empresa o logotipo do Selo de Ética por e-mail e o Certificado pelo correio.</p>

            <div className="gerenciadores">
                <span><u>Gerenciadora:</u></span></div>
            <div className="gerenciadores">
                <span><b>IdEQ Treinamento e Assessoria Empresarial Ltda.</b></span>
                <span>Contato: Cesar Ken Mori</span>
                <span>Fone: (11) 99631-7131</span>
                <span>e-mail: ideqdir@ideq21.com.br</span>
            </div>
            <div className="gerenciadores"><span><u>Organismos de Auditoria Homologados</u></span></div>
            <div className="gerenciadores">
                <span><b>ABS Quality Evaluations, Inc.</b></span>
                <span>Contato: Fernando Pimenta</span>
                <span>Fone: + (55) 11 2595-4216</span>
                <span>Celular: + (55) 11 98115-2510</span>
                <span>e-mail: fpimenta@abs-qe.com</span>
                <span>site: <a target="_blank" href="http://www.abs-qe.com/pt/" rel="noopener noreferrer">www.abs-qe.com</a></span>

            </div>
            <div className="gerenciadores">
                <span><b>BSI Brasil Sistemas de Gestão Ltda.</b></span>
                <span>Contato: Leonardo Soares Fernandes</span>
                <span>Fone: (11) 2148 - 9600</span>       
                <span>e-mail: leonardo.fernandes@bsigroup.com</span>
                <span>site: <a target="_blank" href="https://www.bsigroup.com/pt-BR" rel="noopener noreferrer">www.bsigroup.com/pt-BR</a></span>
            </div>

            <div className="gerenciadores">
                <span><b>BVQI - Bureau Veritas Certification</b></span>                
                <span>Fone: (11) 2655 - 9001</span>                
                <span>e-mail: comercial.cea@bureauveritas.com</span>
                <span>site: <a target="_blank" href="https://www.bureauveritas.com.br/pt-br" rel="noopener noreferrer">www.bureauveritas.com.br</a></span>
            </div>
            <div className="gerenciadores">
                <span><b>DQS do Brasil Ltda.</b></span>
                <span>Contato: Alexandre Oliveira</span>
                <span>Fone: (11) 5696 – 5928</span>              
                <span>e-mail: alexandre.oliveira@dqs.de</span>                
                <span>site: <a target="_blank" href="https://www.dqsglobal.com/pt-br/" rel="noopener noreferrer">www.dqsglobal.com</a></span>
            </div>
            <div className="gerenciadores" style={{marginBottom: 20}}>
                <span><b>Fundação Vanzolini - FCAV</b></span>
                <span>Contato: Simone Custódio</span>
                <span>Fone: (11) 3913 - 7100 ramal 129</span>                
                <span>e-mail: certific@vanzolinicert.org.br</span>
                <span>site: <a target="_blank" href="https://www.vanzolini.org.br" rel="noopener noreferrer">www.vanzolini.org.br</a></span>
            </div>

        </div>
    )
}

export default Selo;
